import React from 'react';
import { CheckCircle, Play, Star, Users, Check, ArrowRight, Code, Workflow, Book } from 'lucide-react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const TestingCourseLanding = () => {
  const features = [
    {
      icon: <Code className="h-12 w-12 text-blue-600" />,
      title: "Real-World Testing",
      description: "Learn with practical examples from production applications"
    },
    {
      icon: <Play className="h-12 w-12 text-blue-600" />,
      title: "40+ Video Lessons",
      description: "Comprehensive video tutorials with downloadable resources"
    },
    {
      icon: <Users className="h-12 w-12 text-blue-600" />,
      title: "Active Community",
      description: "Join thousands of developers in our Discord community"
    },
    {
      icon: <Workflow className="h-12 w-12 text-blue-600" />,
      title: "CI/CD Integration",
      description: "Learn to automate your testing workflow"
    },
    {
      icon: <Book className="h-12 w-12 text-blue-600" />,
      title: "Documentation",
      description: "Access detailed written guides and references"
    },
    {
      icon: <Star className="h-12 w-12 text-blue-600" />,
      title: "Project-Based",
      description: "Build and test real applications as you learn"
    }
  ];

  const pricingTiers = [
    {
      name: "Starter",
      price: "49",
      description: "Perfect for beginners getting started with testing",
      features: [
        "Fundamentals module access",
        "Basic testing patterns",
        "Community access",
        "Exercise files",
      ],
      buttonText: "Get Started",
      popular: false
    },
    {
      name: "Pro",
      price: "149",
      description: "Most popular choice for professional developers",
      features: [
        "All Starter features",
        "Advanced testing patterns",
        "Integration testing",
        "CI/CD workflows",
        "Premium support",
        "Team collaboration features"
      ],
      buttonText: "Get Pro Access",
      popular: true
    },
    {
      name: "Team",
      price: "399",
      description: "For development teams and organizations",
      features: [
        "All Pro features",
        "Team management dashboard",
        "Custom workshops",
        "Priority support",
        "Team analytics",
        "Custom invoicing"
      ],
      buttonText: "Contact Sales",
      popular: false
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <header className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-5xl font-bold mb-6">Master JavaScript Testing</h1>
            <p className="text-xl mb-8">The most comprehensive testing course for JavaScript developers</p>
            <div className="flex justify-center gap-4">
              <button className="bg-white text-blue-600 px-8 py-3 rounded-full font-semibold hover:bg-blue-50 transition-colors">
                Start Learning Now
              </button>
              <button className="border border-white text-white px-8 py-3 rounded-full font-semibold hover:bg-blue-700 transition-colors">
                View Curriculum
              </button>
            </div>
            <div className="mt-12">
              <picture>
                <source srcset="/public/ai-wp-plugin-webp-google-min.webp" />
                <img src="/public/ai-wp-plugin-png-min.png" alt="lighthouse 100 score" decoding="async" loading="eager" width="1617" height="687" />
              </picture>
              {/* src="blob:https://www.corewebvitals.io/81920258-b1dc-45fc-ba1d-ce2a2a6756a0" */}
              <img class="finalimage" width="1617" height="687" alt="lighthouse 100 score" decoding="async" src="/public/ai-wp-plugin-png.webp" />
            </div>
          </div>
        </div>
      </header>

      {/* Social Proof */}
      {/* <section className="py-8 bg-white">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center items-center gap-8 opacity-70">
            <img src="/api/placeholder/120/40" alt="Company 1" />
            <img src="/api/placeholder/120/40" alt="Company 2" />
            <img src="/api/placeholder/120/40" alt="Company 3" />
            <img src="/api/placeholder/120/40" alt="Company 4" />
          </div>
        </div>
      </section> */}

      {/* Features Grid */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Everything You Need to Master Testing</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <Card key={index} className="p-6">
                <CardContent className="space-y-4">
                  {feature.icon}
                  <h3 className="text-xl font-semibold">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Course Preview */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold mb-4">See What You'll Learn</h2>
              <p className="text-xl text-gray-600">Watch a preview of our comprehensive curriculum</p>
            </div>
            <div className="aspect-video bg-gray-200 rounded-lg overflow-hidden">
              <img 
                src="/api/placeholder/800/450" 
                alt="Course Video Preview" 
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">Choose Your Plan</h2>
            <p className="text-xl text-gray-600">Start learning at your own pace, with lifetime access</p>
          </div>
          <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {pricingTiers.map((tier, index) => (
              <Card key={index} className={`p-6 ${tier.popular ? 'border-2 border-blue-500 relative' : ''}`}>
                {tier.popular && (
                  <div className="absolute top-0 right-0 bg-blue-500 text-white px-4 py-1 rounded-bl-lg rounded-tr-lg text-sm font-medium">
                    Most Popular
                  </div>
                )}
                <CardContent className="space-y-6">
                  <div className="space-y-2">
                    <h3 className="text-2xl font-bold">{tier.name}</h3>
                    <div className="text-4xl font-bold">
                      ${tier.price}
                      <span className="text-base font-normal text-gray-600">/lifetime</span>
                    </div>
                    <p className="text-gray-600">{tier.description}</p>
                  </div>
                  <ul className="space-y-3">
                    {tier.features.map((feature, featureIndex) => (
                      <li key={featureIndex} className="flex items-center">
                        <Check className="h-5 w-5 text-green-500 mr-3" />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                  <button className={`w-full py-3 rounded-full font-semibold transition-colors ${
                    tier.popular 
                      ? 'bg-blue-600 text-white hover:bg-blue-700' 
                      : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                  }`}>
                    {tier.buttonText}
                  </button>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonial Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <img 
              src="/api/placeholder/64/64" 
              alt="Student" 
              className="rounded-full mx-auto mb-6"
            />
            <p className="text-xl mb-6 italic">"This course completely transformed how I approach testing. The practical examples and in-depth explanations made complex testing concepts easy to understand."</p>
            <div className="font-semibold">Sarah Chen</div>
            <div className="text-gray-600">Senior Developer at TechCorp</div>
          </div>
        </div>
      </section>

      {/* Final CTA */}
      <section className="bg-blue-600 text-white py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Transform Your Testing Skills?</h2>
          <p className="text-xl mb-8">Join over 10,000 developers who have leveled up their testing game</p>
          <button className="bg-white text-blue-600 px-8 py-3 rounded-full font-semibold hover:bg-blue-50 transition-colors inline-flex items-center">
            Get Started Now
            <ArrowRight className="ml-2 h-5 w-5" />
          </button>
        </div>
      </section>
    </div>
  );
};

export default TestingCourseLanding;